import React from "react";
import { selectIsLoading } from "../../../redux/LoadingReducer";
import { useSelector } from "react-redux";

function Loader({ loaderStyle, containerClassName }) {
  const isLoading = useSelector(selectIsLoading);
  return (
    <>
      {isLoading && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999999,
            backgroundColor: "#ffffff80",
          }}
          className={containerClassName}
        >
          <img
            src="/gif/loader.gif"
            alt=""
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      )}
    </>
  );
}

export default Loader;
