import Loader from "./components/common/loader/Loader";
import SnackBar from "./components/snackBar/SnackBar";
import ReactRoute from "./reactRoute/ReactRoute";

function App() {
  return (
    <div className="App">
      <SnackBar/>
      <Loader/>
      <ReactRoute />
    </div>
  );
}

export default App;