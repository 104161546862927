import React, { useEffect } from "react";
import useFirebaseData from "../../hooks/useFirebaseData";

function FormData() {
  const { fetchData, data } = useFirebaseData();
  useEffect(() => {
    const fnGetData = async () => {
      await fetchData("Jeffery");
    };
    fnGetData();
  }, []);
  console.log(data,'data')
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        width: "100%",
        padding: "36px",
        gap: "36px",
        background: "#eee",
        minHeight: "90dvh",
      }}
    >
      {data?.map((fData) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.06)",
              borderRadius: "20px",
              backgroundColor: "#FFF",
              padding: "20px",
              height: "fit-content",
            }}
          >
            <h1 style={{ fontSize: "36px", color: "#091242" }}>
              {fData?.firstName} {fData?.lastName}
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.email}
                </span>
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.phone}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.streetAddress}
                </span>
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.city}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.state}
                </span>
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.zipCode}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ color: "#666C89", fontSize: "20px" }}>
                  {fData?.extraDetail}
                </span>
              </div>
              <h1 style={{ fontSize: "26px", color: "#091242" }}>Trucks</h1>
              {fData?.trucks?.map((data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span style={{ color: "#666C89", fontSize: "20px" }}>
                      {data?.numberOfTrucks}: {" "}{data?.truckType}
                    </span>
                    <span style={{ color: "#666C89", fontSize: "20px" }}>
                      
                    </span>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <a href={fData?.w9File} 
                  style={{
                    color: "#666C89",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    cursor: "pointer"
                  }}
                >
                  W9 Form
                  <i class="ri-eye-line" style={{ color: "#666", cursor: "pointer"  }}></i>
                </a>
                <a href={fData?.mcAuthorityLetterFile}
                  style={{
                    color: "#666C89",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    cursor: "pointer"
                  }}
                >
                  MC Authority Letter File
                  <i class="ri-eye-line" style={{ color: "#666", cursor: "pointer"  }}></i>
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <a href={fData?.noaForFactoringCompanyFile} 
                  style={{
                    color: "#666C89",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    cursor: "pointer"
                  }}
                >
                  NOA For Factoring CompanyFile
                  <i class="ri-eye-line" style={{ color: "#666", cursor: "pointer"  }}></i>
                </a>
                <a href={fData?.certificateOfInsuranceFile}
                  style={{
                    color: "#666C89",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    cursor: "pointer"
                  }}
                >
                  Certificate Of Insurance File
                  <i class="ri-eye-line" style={{ color: "#666", cursor: "pointer" }}></i>
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default FormData;