import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth"
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDF9qj6fyjy5RfYtO0hwbWH0jlN239D58I",
  authDomain: "rana-ubaidullah.firebaseapp.com",
  projectId: "rana-ubaidullah",
  storageBucket: "rana-ubaidullah.appspot.com",
  messagingSenderId: "654814330875",
  appId: "1:654814330875:web:085cb461db35b552eb555e",
  measurementId: "G-0V8TXGCFQ2"
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider()
export const storage = getStorage(app)
export const db = getFirestore(app)