
import { configureStore } from '@reduxjs/toolkit';
import { slice as snackBarSlice } from './SnackBarReducer';
import { fiIndustriesApi } from './storeApi';
import {slice as loadingBarSlice} from './LoadingReducer';
import {slice as loginReducer} from './LoginReducer'
import {slice as handleMultilingualReducer} from './HandleMultilingualReducer'
const store = configureStore({
  reducer: {
    HandleMultilingualReducer: handleMultilingualReducer.reducer,
    SnackBarReducer: snackBarSlice.reducer,
    LoginReducer: loginReducer.reducer,
    LoadingReducer: loadingBarSlice.reducer,
    [fiIndustriesApi.reducerPath]: fiIndustriesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(fiIndustriesApi.middleware),
});

export default store;
