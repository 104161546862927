import React from "react";
import useHandleLanguage from "../../../hooks/useHandleLanguage";

function DzText({ children, style, className, maxLength="1000" }) {
  const { selectedLanguage } = useHandleLanguage();

  let text;
  if (typeof children === "object" && children !== null) {
    text = children[selectedLanguage || "en"] || children["en"];
  } else if (typeof children === "string") {
    text = children;
  } else {
    text = "";
  }

  if (maxLength && text?.length > maxLength) {
    text = text.slice(0, maxLength) + "...";
  }

  return (
    <span style={style} className={className}>
      {text}
    </span>
  );
}

export default DzText;
