import React, { useState } from "react";
import Input from "../common/input/Input";
import TruckFiled from "./TruckFiled";
import useFirebaseData from "../../hooks/useFirebaseData";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";
import useFirebaseImage from "../../hooks/useFirebaseImage";

function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    extraDetail: "",
    trucks: [{ numberOfTrucks: 1, truckType: "Truck Type" }],
    w9File: "",
    w9FileName: "",
    mcAuthorityLetterFile: "",
    mcAuthorityLetterFileName: "",
    certificateOfInsuranceFile: "",
    certificateOfInsuranceFileName: "",
    noaForFactoringCompanyFile: "",
    noaForFactoringCompanyFileName: "",
  });
  const { fnShowSnackBar } = useSnackBarManager();
  const { imageUrl, uploadProgress, uploadImage, uploadImages, isLoading } =
    useFirebaseImage();
  const { addData } = useFirebaseData();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];
    try {
      const uploadedImageUrl = await uploadImage(file);
      setFormData({
        ...formData,
        [name]: uploadedImageUrl?.url,
        [`${name}Name`]: file.name,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      fnShowSnackBar("Error uploading file", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData?.firstName) {
      fnShowSnackBar("Please enter your first name", true);
    } else if (!formData?.lastName) {
      fnShowSnackBar("Please enter your last name", true);
    } else if (!formData?.email) {
      fnShowSnackBar("Please enter your email", true);
    } else if (!formData?.phone) {
      fnShowSnackBar("Please enter your phone number", true);
    } else if (!formData?.streetAddress) {
      fnShowSnackBar("Please enter your street address", true);
    } else if (!formData?.city) {
      fnShowSnackBar("Please enter your city", true);
    } else if (!formData?.state) {
      fnShowSnackBar("Please enter your state", true);
    } else if (!formData?.zipCode) {
      fnShowSnackBar("Please enter your zip code", true);
    } else if (!formData?.trucks) {
      fnShowSnackBar("Please enter your trucks", true);
    } else if (!formData?.w9File) {
      fnShowSnackBar("Please upload your W9", true);
    } else if (!formData?.mcAuthorityLetterFile) {
      fnShowSnackBar("Please upload your MC Authority Letter", true);
    } else if (!formData?.certificateOfInsuranceFile) {
      fnShowSnackBar("Please upload your Certificate of Insurance", true);
    } else if (!formData?.noaForFactoringCompanyFile) {
      fnShowSnackBar("Please upload your NOA for Factoring Company", true);
    } else {
      const formDataToSend = new FormData();
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("streetAddress", formData.streetAddress);
      formDataToSend.append("city", formData.city);
      formDataToSend.append("state", formData.state);
      formDataToSend.append("zipCode", formData.zipCode);
      formDataToSend.append("extraDetail", formData.extraDetail);
      formDataToSend.append("trucks", JSON.stringify(formData.trucks));
      formDataToSend.append("w9File", formData.w9File);
      formDataToSend.append(
        "mcAuthorityLetterFile",
        formData.mcAuthorityLetterFile
      );
      formDataToSend.append(
        "certificateOfInsuranceFile",
        formData.certificateOfInsuranceFile
      );
      formDataToSend.append(
        "noaForFactoringCompanyFile",
        formData.noaForFactoringCompanyFile
      );
      await addData("Jeffery", formData)
        .then(() => {
          fnShowSnackBar("Thank you for your submission!", "success");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            streetAddress: "",
            city: "",
            state: "",
            zipCode: "",
            extraDetail: "",
            trucks: [{ numberOfTrucks: 1, truckType: "Truck Type" }],
            w9File: null,
            w9FileName: null,
            mcAuthorityLetterFile: null,
            mcAuthorityLetterFileName: null,
            certificateOfInsuranceFile: null,
            certificateOfInsuranceFileName: null,
            noaForFactoringCompanyFile: null, 
            noaForFactoringCompanyFileName: null,
          });
        })
        .catch((error) => {
          fnShowSnackBar(error.message, "error");
        });
    }
  };

  return (
    <div className="sec5-1-prt2">
      <div className="sec5-1-prt2-hedng" style={{ color: "#091242" }}>
        Write Detail Right Now & Join Us!
      </div>
      <div className="sec5-1-prt2-1">
        <div className="sec5-1-prt2-1-prt1">
          <Input
            placeholder="First name"
            className="sec5-1-prt2-1-input1-1"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <Input
            placeholder="Last name"
            className="sec5-1-prt2-1-input1-1"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div className="sec5-1-prt2-1-prt1">
          <Input
            placeholder="Email"
            className="sec5-1-prt2-1-input1-1"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <Input
            placeholder="Phone"
            className="sec5-1-prt2-1-input1-1"
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="sec5-1-prt2-1-prt1">
          <Input
            placeholder="Street address"
            className="sec5-1-prt2-1-input1-1"
            name="streetAddress"
            value={formData.streetAddress}
            onChange={handleInputChange}
          />
          <Input
            placeholder="City"
            className="sec5-1-prt2-1-input1-1"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>
        <div className="sec5-1-prt2-1-prt1">
          <Input
            placeholder="State"
            className="sec5-1-prt2-1-input1-1"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
          />
          <Input
            placeholder="Zip code"
            className="sec5-1-prt2-1-input1-1"
            type="number"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleInputChange}
          />
        </div>
        <TruckFiled formData={formData} setFormData={setFormData} />
        <div className="twocolumnphir1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "space-between",
            }}
         >
            <span
              style={{
                width: "100%",
                fontSize: "16px",
                color: "#666C89",
                fontWeight: "400",
              }}
            >
              W9 Form : {formData?.w9FileName}
            </span>
            <div
              style={{
                position: "relative",
                width: "160px",
                cursor: "pointer",
                borderRadius: "4px",
                background: "#F8F8F9",
                border: "1px solid #09124240",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 6px",
              }}
              className="hover-outline"
            >
              <input
                type="file"
                name="w9File"
                onChange={handleFileChange}
                style={{ position: "absolute", inset: "0", opacity: "0" }}
              />
              <span style={{ color: "#666C89" }}>Upload File</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "space-between",
            }}
         >
            <span
              style={{
                width: "100%",
                fontSize: "16px",
                color: "#666C89",
                fontWeight: "400",
              }}
            >
              MC Authority Letter : {formData.mcAuthorityLetterFileName}
            </span>
            <div
              style={{
                position: "relative",
                width: "160px",
                cursor: "pointer",
                borderRadius: "4px",
                background: "#F8F8F9",
                border: "1px solid #09124240",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 6px",
              }}
              className="hover-outline"
            >
              <input
                type="file"
                name="mcAuthorityLetterFile"
                onChange={handleFileChange}
                style={{ position: "absolute", inset: "0", opacity: "0" }}
              />
              <span style={{ color: "#666C89" }}>Upload File</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "space-between",
            }}
         >
            <span
              style={{
                width: "100%",
                fontSize: "16px",
                color: "#666C89",
                fontWeight: "400",
              }}
            >
              Certificate of Insurance : {formData.certificateOfInsuranceFileName}
            </span>
            <div
              style={{
                position: "relative",
                width: "160px",
                cursor: "pointer",
                borderRadius: "4px",
                background: "#F8F8F9",
                border: "1px solid #09124240",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 6px",
              }}
              className="hover-outline"
            >
              <input
                type="file"
                name="certificateOfInsuranceFile"
                onChange={handleFileChange}
                style={{ position: "absolute", inset: "0", opacity: "0" }}
              />
              <span style={{ color: "#666C89" }}>Upload File</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              justifyContent: "space-between",
            }}
         >
            <span
              style={{
                width: "100%",
                fontSize: "16px",
                color: "#666C89",
                fontWeight: "400",
              }}
            >
              NOA for Factoring Company: {formData.noaForFactoringCompanyFileName}
            </span>
            <div
              style={{
                position: "relative",
                width: "160px",
                cursor: "pointer",
                borderRadius: "4px",
                background: "#F8F8F9",
                border: "1px solid #09124240",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 6px",
              }}
              className="hover-outline"
            >
              <input
                type="file"
                name="noaForFactoringCompanyFile"
                onChange={handleFileChange}
                style={{ position: "absolute", inset: "0", opacity: "0" }}
              />
              <span style={{ color: "#666C89" }}>Upload File</span>
            </div>
          </div>
        </div>

        <div className="sec5-1-prt2-1-prt2-1">
          <textarea
            className="sec5-1-prt2-1-prt2-1-msginput"
            placeholder="Extra Detail"
            name="extraDetail"
            value={formData.extraDetail}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="sec1-3" onClick={handleSubmit}>
        Join Us
      </div>
    </div>
  );
}

export default ContactForm;
