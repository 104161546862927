import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

const FaqSection = () => {
  // State to manage which question is open
  const [openQuestion, setOpenQuestion] = useState(null);

  // Refs to manage the animation targets
  const answerRefs = useRef([]);
  answerRefs.current = [];

  // Function to add refs
  const addToRefs = (el) => {
    if (el && !answerRefs.current.includes(el)) {
      answerRefs.current.push(el);
    }
  };

  // Function to toggle the question
  const toggleQuestion = (question) => {
    setOpenQuestion(openQuestion === question ? null : question);
  };

  useEffect(() => {
    answerRefs.current.forEach((ref, index) => {
      if (openQuestion === index) {
        gsap.to(ref, { height: "auto", opacity: 1, duration: 0.5 });
      } else {
        gsap.to(ref, { height: 0, opacity: 0, duration: 0.5 });
      }
    });
  }, [openQuestion]);

  // FAQ data
  const faqs = [
    {
      question: "What services does your dispatch company provide?",
      answer:
        "Our dispatch company offers comprehensive services including load searching, rate negotiation, credit checks, paperwork management, safety and DOT compliance, and billing/factoring services. We aim to maximize your efficiency and profitability by handling all the logistics and administrative tasks.",
    },
    {
      question: "How do I get started with your dispatch services?",
      answer:
        "Getting started is easy! Simply complete our online application, go through our verification and compliance check, participate in the onboarding and training session, and then you can start receiving load assignments tailored to your preferences.",
    },
    {
      question: "What documents are required for the carrier onboarding process?",
      answer:
        "To onboard as a carrier, you will need to provide your carrier details, proof of insurance, DOT compliance documents, and any relevant certifications. Our team will guide you through the exact requirements during the application process.",
    },
    {
      question: "How long does the onboarding process take?",
      answer:
        "The onboarding process typically takes a few days to a week, depending on the completeness of your application and the speed of the verification process. We strive to make this process as quick and smooth as possible.",
    },
    {
      question: "What kind of support can I expect after onboarding?",
      answer:
        "After onboarding, you will receive continuous support from our dispatch team. We provide assistance with load assignments, answer any operational questions, ensure ongoing compliance, and offer help with billing and factoring to keep your business running smoothly.",
    },
  ];

  return (
    <div
      className="flex flex-col items-center gap-[44px] sm:px-[7.7vw] sm:py-[96px] py-[48px] px-[24px] max-w-[1980px] w-full"
      id="sec6-faq"
    >
      <div className="flex flex-col items-center gap-[8px]">
        <div className="flex items-center gap-[12px] ">
          <span className="text-[#101828] ">Faqs</span>
          <span className="w-[30px] border-[1px] border-[#FFB612]"></span>
        </div>
        <h3 className="text-[#091242] sm:text-[42px] text-[24px] font-[600] text-center">
          Frequently asked questions
        </h3>
        <span className="text-[#666C89] sm:text-[14px] text-[16px] text-center">
          Everything you need to know about the product and billing.
        </span>
      </div>

      <div className="flex flex-col md:w-[968px] gap-[32px] w-full">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="flex flex-col items-start mb-[16px] w-full"
          >
            <div
              className="flex items-start justify-between gap-[24px] w-full cursor-pointer"
              onClick={() => toggleQuestion(index)}
            >
              <div className="flex flex-col gap-[8px]">
                <h4 className="text-[#091242] font-[500] sm:text-[18px] text-[20x]">
                  {faq.question}
                </h4>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                className="min-w-[32px]"
              >
                <path
                  d={openQuestion === index ? "M8 12H16" : "M12 8V16M8 12H16"}
                  stroke="#091242"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              ref={addToRefs}
              style={{ height: 0, overflow: "hidden", opacity: 0 }}
            >
              <p className="text-[#475467] mt-[8px]">{faq.answer}</p>
            </div>
            <hr className="border-[#EAECF0]" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
