import React from "react";
import { useSnackBarManager } from "../../hooks/useSnackBarManager";
import DzText from "../common/dzText/DzText";
const SnackBar = () => {
  const { isErrorTrue, isShowSnackBar } = useSnackBarManager();
  return (
    <>
      <div
        style={{
          backgroundColor: isErrorTrue ? "#fd2929" : "#FF5C35",
          transform: !isShowSnackBar ? "translateX(100%)" : "translateX(0)",
          transition: "all 0.3s ease",
          position: "fixed",
          right: "0",
          bottom: "70px",
          zIndex: "10",
          padding: "12px 20px",
          backgroundColor: "#112D4E",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
          borderRadius: "8px",
          minWidth: "344px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <DzText
          children={isShowSnackBar}
          style={{ color: "white" }}
          className={"text-[#F9F7F7] cursor-text capitalize"}
        />
        <span
          style={{
            position: "absolute",
            height: "100%",
            top: "0",
            right: "0",
            width: "1px",
            backgroundColor: "#DBE2EF",
          }}
        >
          <div className="h-full w-full bg-112D4E"></div>
        </span>
      </div>
    </>
  );
};
export default SnackBar;
