import React from "react";

function Services() {
  return (
    <div class="sec2" id="sec2-service">
      <div class="sec2-1">
        <div class="sec2-1-prt1">
          <div class="sec2-1-prt1-txt">OUR SERVICES</div>
          <div class="sec2-1-prt1-line"></div>
        </div>
        <div class="sec2-1-prt2" style={{color: '#091242'}}>
          We Take The Frustration Out Of Your Transportation Job
        </div>
      </div>
      <div class="sec2-boxes1">
        <div class="sec2-boxes">
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img1.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img1.png" alt="" />
            <div class="sec2-2-prt2">Load Searching</div>
            <div class="sec2-2-prt3">
              Our dedicated team ensures you find the best loads efficiently,
              minimizing downtime and maximizing your earnings. We handle the
              heavy lifting so you can focus on the road.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img2.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img2.png" alt="" />

            <div class="sec2-2-prt2">Rate Negotiation</div>
            <div class="sec2-2-prt3">
              We leverage our industry expertise to negotiate the best possible
              rates for your hauls, ensuring you get the maximum value for your
              efforts without the hassle.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img3.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img3.png" alt="" />

            <div class="sec2-2-prt2">Credits Checks</div>
            <div class="sec2-2-prt3">
              We perform thorough credit checks on shippers and brokers,
              ensuring you work with reliable partners and reducing the risk of
              non-payment.
            </div>
          </div>
        </div>
        <div class="sec2-boxes">
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img4.png" alt="" />
            <div class="sec2-2-prt2">Paperwork Management</div>
            <div class="sec2-2-prt3">
              Let us handle all the paperwork, from BOLs to invoices, so you can
              concentrate on driving. We streamline your administrative tasks
              for seamless operations.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img5.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-im5.png" alt="" />

            <div class="sec2-2-prt2">Safety/Dot Compliance</div>
            <div class="sec2-2-prt3">
              Our team ensures your fleet remains compliant with all safety
              regulations and DOT standards, keeping your operations running
              smoothly and legally.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img6.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img6.png" alt="" />

            <div class="sec2-2-prt2">Billing/Factoring Service</div>
            <div class="sec2-2-prt3">
              We manage your billing processes and offer factoring services to
              improve your cash flow. Get paid faster and keep your business
              moving forward without financial stress.
            </div>
          </div>
        </div>
      </div>

      <div class="sec2-boxes2">
        <div class="sec2-boxes">
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img1.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img1.png" alt="" />

            <div class="sec2-2-prt2">Load Searching</div>
            <div class="sec2-2-prt3">
              Our dedicated team ensures you find the best loads efficiently,
              minimizing downtime and maximizing your earnings. We handle the
              heavy lifting so you can focus on the road.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img2.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img2.png" alt="" />

            <div class="sec2-2-prt2">Rate Negotiation</div>
            <div class="sec2-2-prt3">
              We leverage our industry expertise to negotiate the best possible
              rates for your hauls, ensuring you get the maximum value for your
              efforts without the hassle.
            </div>
          </div>
        </div>
        <div class="sec2-boxes">
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img3.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img3.png" alt="" />

            <div class="sec2-2-prt2">Credits Checks</div>
            <div class="sec2-2-prt3">
              We perform thorough credit checks on shippers and brokers,
              ensuring you work with reliable partners and reducing the risk of
              non-payment.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img4.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img4.png" alt="" />

            <div class="sec2-2-prt2">Paperwork Management</div>
            <div class="sec2-2-prt3">
              Let us handle all the paperwork, from BOLs to invoices, so you can
              concentrate on driving. We streamline your administrative tasks
              for seamless operations.
            </div>
          </div>
        </div>

        <div class="sec2-boxes">
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img5.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img5.png" alt="" />

            <div class="sec2-2-prt2">Safety/Dot Compliance</div>
            <div class="sec2-2-prt3">
              Our team ensures your fleet remains compliant with all safety
              regulations and DOT standards, keeping your operations running
              smoothly and legally.
            </div>
          </div>
          <div class="sec2-2">
            <img class="sec2-2-prt1" src="images/sec2-img6.png" alt="" />
            <img class="sec2-2-prt-img" src="images/sec2-img6.png" alt="" />

            <div class="sec2-2-prt2">Billing/Factoring Service</div>
            <div class="sec2-2-prt3">
              We manage your billing processes and offer factoring services to
              improve your cash flow. Get paid faster and keep your business
              moving forward without financial stress.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
