import { createSlice } from "@reduxjs/toolkit";
export const slice = createSlice({
    name: "HandleMultilingualReducer",
    initialState: {
        selectedLanguage: "en",
    },
    reducers: {
        setSelectedLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
    },
});
export const { setSelectedLanguage } = slice.actions;
export const selectSelectedLanguage = (state) =>
    state.HandleMultilingualReducer.selectedLanguage;
export default slice.reducer;
