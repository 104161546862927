import React from 'react'

function GetStarted() {
  return (
<div className="Sec7">
        <div className="Sec7-1">Join Us Today to Get Started!</div>
        <a href='#sec5-form' style={{textDecoration: 'none'}} className="Sec7-2">Join Us</a>
      </div>
  )
}

export default GetStarted