import { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebase";
import { useSnackBarManager } from "./useSnackBarManager";
import useHandleLoading from "./useHandleLoading";

function useFirebaseImage() {
  const [imageUrl, setImageUrl] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { fnHandleLoading } = useHandleLoading();
  const { fnShowSnackBar } = useSnackBarManager();

  const uploadImage = async (file, returnType = "url") => {
    fnHandleLoading(true);
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, "images/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
  
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading image:", error);
          fnShowSnackBar("Error uploading image", "error");
          fnHandleLoading(false);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageUrl(downloadURL);
            fnShowSnackBar("Image uploaded successfully");
            fnHandleLoading(false);
            
            if (returnType === "url") {
              resolve({ url: downloadURL, file: file });
            } else if (returnType === "path") {
              resolve({ path: storageRef.fullPath, file: file });
            }
          });
        }
      );
    });
  };
  
  

  return { imageUrl, uploadProgress, uploadImage };
}

export default useFirebaseImage;
