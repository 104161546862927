import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "LoginReducer",
  initialState: {
    isLogin: false,
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
  },
});

export const { setIsLogin } = slice.actions;

export const selectIsLogin = (state) => state.LoginReducer.isLogin;
export default slice.reducer;
