import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();

  return (
    <div className="sec8">
      <div className="sec8-1">
        <div className="sec8-1-prt1">
          <img
            className=""
            style={{ width: "140px" }}
            src="images/Logo-jefferyu.png"
            alt=""
          />
        </div>
        <div className="sec8-1-prt2">
          {location.pathname === "/" ? (
            <>
              <a href="#nav-bar1" className="sec8-3-1-txt1-1">
                <div className="sec8-1-prt2-txt1">Home</div>
              </a>
              <a href="#sec2-service" className="sec8-3-1-txt1-1">
                <div className="sec8-1-prt2-txt1">Services</div>
              </a>
              <a href="#sec6-faq" className="sec8-3-1-txt1-1">
                <div className="sec8-1-prt2-txt1">Faq</div>
              </a>
            </>
          ) : (
            <Link to={"/"} className="text-[#fff] cursor-pointer">
              Back To Home
            </Link>
          )}
        </div>
      </div>
      <div className="sec8-2">
        <div className="sec8-2-prt1">
          <div className="sec8-2-prt1-hd">Opening Hours</div>
          <div className="sec8-2-prt1-txt1">
            <div className="sec8-2-prt1-txt1-1">Weekdays:</div>
            <div className="sec8-2-prt1-txt1-2">8.00am-7:30pm</div>
          </div>
          <div className="sec8-2-prt1-txt2">
            <div className="sec8-2-prt1-txt2-1">Saturday, Sunday:</div>
            <div className="sec8-2-prt1-txt2-2">8:00am-2.00pm</div>
          </div>
        </div>
        <div className="sec8-2-prt2">
          <div className="sec8-2-prt1-hd">Address</div>
          <div className="sec8-2-prt2-txt1">
            30 N Gould St#40390 ste Sheridan, Wyoming 82801
          </div>
        </div>
        <div className="sec8-2-prt3">
          <div className="sec8-2-prt1-hd">Contact</div>
          <div className="sec8-2-prt3-txt1">
            <div className="sec8-2-prt3-txt1-1">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_91_531)">
                  <path
                    d="M17.167 3.33301H3.83366C2.91699 3.33301 2.17533 4.08301 2.17533 4.99967L2.16699 14.9997C2.16699 15.9163 2.91699 16.6663 3.83366 16.6663H17.167C18.0837 16.6663 18.8337 15.9163 18.8337 14.9997V4.99967C18.8337 4.08301 18.0837 3.33301 17.167 3.33301ZM16.3337 14.9997H4.66699C4.20866 14.9997 3.83366 14.6247 3.83366 14.1663V6.66634L9.61699 10.283C10.1587 10.6247 10.842 10.6247 11.3837 10.283L17.167 6.66634V14.1663C17.167 14.6247 16.792 14.9997 16.3337 14.9997ZM10.5003 9.16634L3.83366 4.99967H17.167L10.5003 9.16634Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_91_531">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <a
              href="mailto:hello@jefferylogistics.online"
              className="sec8-2-prt3-txt1-2"
              style={{ cursor: "pointer" }}
            >
              hello@jefferylogistics.online
            </a>
          </div>
          <div className="sec8-2-prt3-txt1">
            <div className="sec8-2-prt3-txt1-1">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_91_536)">
                  <path
                    d="M12.1667 6.66667H13.8334V8.33333C13.8334 8.79167 14.2084 9.16667 14.6667 9.16667C15.1251 9.16667 15.5001 8.79167 15.5001 8.33333V6.66667H17.1667C17.6251 6.66667 18.0001 6.29167 18.0001 5.83333C18.0001 5.375 17.6251 5 17.1667 5H15.5001V3.33333C15.5001 2.875 15.1251 2.5 14.6667 2.5C14.2084 2.5 13.8334 2.875 13.8334 3.33333V5H12.1667C11.7084 5 11.3334 5.375 11.3334 5.83333C11.3334 6.29167 11.7084 6.66667 12.1667 6.66667ZM16.5084 12.725L14.3917 12.4833C13.8834 12.425 13.3834 12.6 13.0251 12.9583L11.4917 14.4917C9.1334 13.2917 7.20007 11.3667 6.00007 9L7.54173 7.45833C7.90007 7.1 8.07507 6.59167 8.01673 6.09167L7.77507 3.99167C7.6834 3.15 6.96673 2.50833 6.12507 2.50833H4.6834C3.74173 2.50833 2.9584 3.29167 3.01673 4.23333C3.4584 11.35 9.15007 17.0333 16.2584 17.475C17.2001 17.5333 17.9834 16.75 17.9834 15.8083V14.3667C17.9917 13.5333 17.3501 12.8167 16.5084 12.725Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_91_536">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <a
              href="tel:773 840-1622"
              className="sec8-2-prt3-txt1-2"
              style={{ cursor: "pointer" }}
            >
              773 840-1622
            </a>
          </div>
        </div>
      </div>
      <div className="sec8-4">
        <div className="sec8-4-1">
          <div className="sec8-2-prt1">
            <div className="sec8-2-prt1-hd sec8-4-prt1-hd">Opening Hours</div>
            <div className="sec8-2-prt1-txt1">
              <div className="sec8-2-prt1-txt1-1 sec8-4-prt1-txt1-1">
                Weekdays:
              </div>
              <div className="sec8-2-prt1-txt1-2 sec8-4-prt1-txt1-1">
                8.00am-7:30pm
              </div>
            </div>
            <div className="sec8-2-prt1-txt2">
              <div className="sec8-2-prt1-txt2-1 sec8-4-prt1-txt1-1">
                Saturday, Sunday:
              </div>
              <div className="sec8-2-prt1-txt2-2 sec8-4-prt1-txt1-1">
                8:00am-2.00pm
              </div>
            </div>
          </div>
          <div className="sec8-2-prt2">
            <div className="sec8-2-prt1-hd sec8-4-prt1-hd">Address</div>
            <div className="sec8-2-prt2-txt1">
              30 N Gould St#40390 ste Sheridan, Wyoming 82801
            </div>
          </div>
        </div>
        <div className="sec8-4-2">
          <div className="sec8-2-prt3">
            <div className="sec8-2-prt1-hd sec8-4-prt1-hd">Contact</div>
            <div className="sec8-2-prt3-txt1">
              <div className="sec8-2-prt3-txt1-1">
                <svg
                  className="sec8-2-prt3-txt1-1-icn"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_91_531)">
                    <path
                      d="M17.167 3.33301H3.83366C2.91699 3.33301 2.17533 4.08301 2.17533 4.99967L2.16699 14.9997C2.16699 15.9163 2.91699 16.6663 3.83366 16.6663H17.167C18.0837 16.6663 18.8337 15.9163 18.8337 14.9997V4.99967C18.8337 4.08301 18.0837 3.33301 17.167 3.33301ZM16.3337 14.9997H4.66699C4.20866 14.9997 3.83366 14.6247 3.83366 14.1663V6.66634L9.61699 10.283C10.1587 10.6247 10.842 10.6247 11.3837 10.283L17.167 6.66634V14.1663C17.167 14.6247 16.792 14.9997 16.3337 14.9997ZM10.5003 9.16634L3.83366 4.99967H17.167L10.5003 9.16634Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_91_531">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <a
                href="mailto:hello@jefferylogistics.online"
                className="sec8-2-prt3-txt1-2"
                style={{ cursor: "pointer" }}
              >
                hello@jefferylogistics.online
              </a>
            </div>
            <div className="sec8-2-prt3-txt1">
              <div className="sec8-2-prt3-txt1-1">
                <svg
                  className="sec8-2-prt3-txt1-1-icn"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_91_536)">
                    <path
                      d="M12.1667 6.66667H13.8334V8.33333C13.8334 8.79167 14.2084 9.16667 14.6667 9.16667C15.1251 9.16667 15.5001 8.79167 15.5001 8.33333V6.66667H17.1667C17.6251 6.66667 18.0001 6.29167 18.0001 5.83333C18.0001 5.375 17.6251 5 17.1667 5H15.5001V3.33333C15.5001 2.875 15.1251 2.5 14.6667 2.5C14.2084 2.5 13.8334 2.875 13.8334 3.33333V5H12.1667C11.7084 5 11.3334 5.375 11.3334 5.83333C11.3334 6.29167 11.7084 6.66667 12.1667 6.66667ZM16.5084 12.725L14.3917 12.4833C13.8834 12.425 13.3834 12.6 13.0251 12.9583L11.4917 14.4917C9.1334 13.2917 7.20007 11.3667 6.00007 9L7.54173 7.45833C7.90007 7.1 8.07507 6.59167 8.01673 6.09167L7.77507 3.99167C7.6834 3.15 6.96673 2.50833 6.12507 2.50833H4.6834C3.74173 2.50833 2.9584 3.29167 3.01673 4.23333C3.4584 11.35 9.15007 17.0333 16.2584 17.475C17.2001 17.5333 17.9834 16.75 17.9834 15.8083V14.3667C17.9917 13.5333 17.3501 12.8167 16.5084 12.725Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_91_536">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <a
                href="tel:7738401622"
                className="sec8-2-prt3-txt1-2"
                style={{ cursor: "pointer" }}
              >
                773 840-1622
              </a>
            </div>
          </div>
          <div className="sec8-3-1 sec8-3-4 sec8-4-4">
            <Link to="/privacyPolicy" className="sec8-3-1-txt1-1 sec8-3-1-txt1">
              Privacy Policy
            </Link>
            <Link
              to="/termsConditions"
              className="sec8-3-1-txt1 sec8-3-1-txt1-1"
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>

      <div className="sec8-3">
        <div className="sec8-3-1 sec8-3-3 sec8-4-3">
          © 2022 Jeffery logistics. All Rights Reserved..
        </div>
        <div className="sec8-3-1 sec8-3-4 sec8-4-5">
          <Link to="/privacyPolicy" className="sec8-3-1-txt1-1 sec8-3-1-txt1">
            Privacy Policy
          </Link>
          <Link to="/termsConditions" className="sec8-3-1-txt1 sec8-3-1-txt1-1">
            Terms & Conditions
          </Link>
        </div>
        <div className="sec8-3-2">
          <a
            className="nav1-2-icn1-1"
            href="https://www.facebook.com/profile.php?id=61559504889232&mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <div className="nav1-2-icn1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                color="#ffffff"
                fill="none"
              >
                <path
                  d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.9265 8.02637H13.9816C12.9378 8.02637 12.0894 8.86847 12.0817 9.91229L11.9964 21.4268M10.082 14.0017H14.8847"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            className="nav1-2-icn1-1"
            href="https://www.instagram.com/jeffery_logistics/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="nav1-2-icn1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                color="#ffffff"
                fill="none"
              >
                <path
                  d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M17.5078 6.5L17.4988 6.5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </a>
          <a
            className="nav1-2-icn1-1"
            href="https://www.linkedin.com/company/jaffery-logistic/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="nav1-2-icn1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                color="#ffffff"
                fill="none"
              >
                <path
                  d="M7 10V17"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 13V17M11 13C11 11.3431 12.3431 10 14 10C15.6569 10 17 11.3431 17 13V17M11 13V10"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.00801 7L6.99902 7"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
