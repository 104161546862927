import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../constants/Index.js";
export const fiIndustriesApi = createApi({
  reducerPath: "fiIndustriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: Config.serverApiUrl,
    prepareHeaders: async (headers, { getState, endpoint }) => {

      return headers;
    },
  }),
  tagTypes: [
  ],
  endpoints: (builder) => ({
    getDraft: builder.query({ query: (draftId) => `email/drafts/${draftId}` }),
  }),
});
export const {
  useGetDraftQuery,
} = fiIndustriesApi;
