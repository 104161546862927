import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./RouteConstants";
import Home from "../pages/home";
import TopNavbar from "../components/navbar/TopNavbar.jsx";
import BottomNavbar from "../components/navbar/BottomNavbar.jsx";
import Footer from "../components/footer/Footer.jsx";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy.jsx";
import TermsConditions from "../pages/termsConditions/TermsConditions.jsx";
import FormData from "../pages/formData/FormData.jsx";
import ScrollToTop from "./ScrollToTop";

const ReactRoute = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <TopNavbar />
      <BottomNavbar />
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path="/thisIsJustForAdmin" element={<FormData />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsConditions" element={<TermsConditions />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default ReactRoute;
