import React, { useRef, useState } from "react";
import Input from "../common/input/Input";
import useOutsideClick from "../../hooks/useOutsideClick";

const truckTypes = [
  "Hot-SHOT",
  "Truck Type",
  "BOX-TRUCK",
  "POWER-ONLY",
  "FLAT-BED",
  "DRY-VAN",
  "REEFER",
  "Other",
];

function TruckFiled({ formData, setFormData }) {
  const [truckTypeDropdownVisible, setTruckTypeDropdownVisible] =
    useState(null);
  const truckTypeRef = useRef();
  const handleTruckInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTrucks = [...formData.trucks];
    updatedTrucks[index][name] = value;
    setFormData({
      ...formData,
      trucks: updatedTrucks,
    });
  };

  const handleTruckTypeSelect = (index, type) => {
    const updatedTrucks = [...formData.trucks];
    updatedTrucks[index].truckType = type;
    setFormData({
      ...formData,
      trucks: updatedTrucks,
    });
    setTruckTypeDropdownVisible(null);
  };

  const toggleTruckTypeDropdown = (index) => {
    setTruckTypeDropdownVisible(
      truckTypeDropdownVisible === index ? null : index
    );
  };

  const addTruck = () => {
    setFormData({
      ...formData,
      trucks: [
        ...formData.trucks,
        { numberOfTrucks: 1, truckType: "Truck Type" },
      ],
    });
  };

  const removeTruck = (index) => {
    const updatedTrucks = formData.trucks.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      trucks: updatedTrucks,
    });
  };
  useOutsideClick(truckTypeRef, () => setTruckTypeDropdownVisible(null));
  return (
    <>
      {formData.trucks.map((truck, index) => (
        <div className="sec5-1-prt2-1-prt1" key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
              position: "relative",
            }}
          >
            <Input
              placeholder="Number of Trucks"
              outerClassName="focus-ouline"
              style={{
                width: "80px",
                display: "flex",
                alignItems: "center",
                height: "60px",
                color: "#666C89",
                position: "relative",
                borderRadius: "8px",
                border: "1px solid #D6D6DC",
                backgroundColor: "#F8F8F9",
                lineHight: "24px",
                fontSize: "16px",
                fontWeight: "400",
                padding: "12px 18px",
                outline: "none",
              }}
              type="number"
              name="numberOfTrucks"
              value={truck.numberOfTrucks}
              onChange={(e) => handleTruckInputChange(index, e)}
            />
            <span ref={truckTypeRef} style={{ width: "100%" }}>
              <div
                className="sec5-1-prt2-1-input1-1 sec5-1-prt2-1-input1-1-pading"
                onClick={() => toggleTruckTypeDropdown(index)}
              >
                <div id="sec5-1-prt2-1-input1-1-slect">{truck.truckType}</div>
                  <i
                    className={`ri-arrow-down-s-line ${
                      truckTypeDropdownVisible === index
                        ? "rotate-[180deg]"
                        : ""
                    }`}
                  ></i>
              </div>
              {truckTypeDropdownVisible === index && (
                <div
                  className="sec5-1-prt2-1-input1-1-opt"
                  style={{ cursor: "pointer", zIndex: "999" }}
                >
                  {truckTypes.map((type, idx) => (
                    <div
                      key={idx}
                      className="sec5-1-prt2-1-input1-1-opt1"
                      onClick={() => handleTruckTypeSelect(index, type)}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              )}
            </span>
            <div
              style={{
                padding: "10px",
                height: "60px",
                backgroundColor: "#F8F8F9",
                border: "1px solid #D6D6DC",
                borderRadius: "8px",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              className="hover:!border-[#666C89]"
              onClick={() =>
                index === formData.trucks.length - 1
                  ? addTruck(index)
                  : removeTruck(index)
              }
            >
              <i
                className={`text-[#666C89] ri-${
                  index === formData.trucks.length - 1 ? "add" : "subtract"
                }-fill`}
              ></i>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default TruckFiled;
