import React from "react";

function TermsConditions() {
  return (
    <>
    
      <div className="flex termsConditionsBack items-center justify-center w-full  !h-[300px] sm:!h-[512px]">
        <span className="text-[28px] sm:text-[64px] font-[700] text-[#FFF]">
          Terms and Conditions
        </span>
      </div>
      <div class="Sec9">
        <div class="Sec9-1">
          Welcome to Jeffery Logistics! These Terms and Conditions govern your
          relationship with Jeffery Logistic. Please read these Terms and
          Conditions carefully before using our Service.
        </div>
        <div class="Sec9-2">
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Acceptance of Terms</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Services Provided</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">User Responsibilities</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Payment Terms</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Cancellation and Termination</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Limitation of Liability</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Disclaimer of Warranties</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Disclaimer of Warranties</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Governing Law</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Contact Us</div>
            <div class="Sec9-2-prt2-2">
              By accessing or using our Service, you agree to be bound by these
              Terms. If you disagree with any part of the terms, then you may
              not access the Service.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;
