import React from "react";

function Input({ placeholder, value, type, onChange, className, name, style, outerClassName="sec5-1-prt2-1-input1" }) {
  return (
    <div className={outerClassName}>
      <input
      style={style}
        name={name}
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type || "text"}
      />
    </div>
  );
}

export default Input;
