import React from "react";
import Hero from "../../components/hero/Hero";
import Services from "../../components/services/Services";
import OnBoarding from "../../components/onBoarding/OnBoarding";
import FaqSection from "../../components/faqSection/FaqSection";
import GetStarted from "../../components/getStarted/GetStarted";
import ContactSection from "../../components/contactForm/ContactSection";

function index() {
  return (
    <>

      <Hero /> 
      <div id="sec2-service1">
        <Services />
        <OnBoarding/>
        <ContactSection/>
        <FaqSection/>
        <GetStarted/>
      </div>
    </>
  );
}

export default index;
