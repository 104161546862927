import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function BottomNavbar() {
  const [activeSection, setActiveSection] = useState("");
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        {
          id: "nav-bar1",
          offsetTop: document.getElementById("nav-bar1")?.offsetTop || 0,
        },
        {
          id: "sec2-service",
          offsetTop: document.getElementById("sec2-service")?.offsetTop || 0,
        },
        {
          id: "sec6-faq",
          offsetTop: document.getElementById("sec6-faq")?.offsetTop || 0,
        },
      ];

      const scrollPosition = window.scrollY + window.innerHeight / 2;

      let currentSection = ""; 
      for (let i = sections.length - 1; i >= 0; i--) {
        if (scrollPosition >= sections[i].offsetTop) {
          currentSection = sections[i].id;
          break;
        }
      }

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Set initial active section

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="nav2" id="nav-bar1">
      <Link to={"/"} className="nav2-1">
        <img className="logo2 logo1" src="images/Logo-jefferyu2.png" alt="" />
      </Link>

      <div className="nav2-2">
        {location?.pathname === "/" ? (
          <>
          <a
            className={`nav2-2-prt1-lnk ${
              activeSection === "nav-bar1" ? "active" : ""
            }`}
            href="#nav-bar1"
          >
            <div id="nav2-2-prt2-1">Home</div>
          </a>
          <a
            className={`nav2-2-prt1-lnk ${
              activeSection === "sec2-service" ? "active" : ""
            }`}
            href="#sec2-service"
          >
            <div id="nav2-2-prt2-1">Services</div>
          </a>
          <a
            className={`nav2-2-prt1-lnk ${
              activeSection === "sec6-faq" ? "active" : ""
            }`}
            href="#sec6-faq"
          >
            <div id="nav2-2-prt2-1">Faq</div>
          </a>
          </>
        ): (
          <Link to={"/"} style={{color: "#666C89"}} >
            Back to Home
          </Link>
        )}
      </div>
    </div>
  );
}

export default BottomNavbar;
