import React from 'react'
import ContactForm from './ContactForm'

function ContactSection() {
  return (
    <div className="sec5" id='sec5-form'>
    <div className="sec5-1">
      <div className="sec5-1-prt1">
        <div className="sec5-1-prt1-1">
          <div className="sec5-1-prt1-1-num1">
            <div className="sec5-1-prt1-1-num">1</div>
          </div>
          <div className="sec5-1-prt1-1-txt">
            <div className="sec5-1-prt1-1-txt1">Submit a Request.</div>
            <div className="sec5-1-prt1-1-txt2">
              Ready to streamline your logistics and maximize your trucking
              efficiency? Simply submit a request with your requirements, and
              our expert team will handle the rest. Take the first step
              towards smoother operations today.
            </div>
          </div>
        </div>
        <div className="sec5-1-prt1-1">
          <div className="sec5-1-prt1-1-num1">
            <div className="sec5-1-prt1-1-num">2</div>
          </div>
          <div className="sec5-1-prt1-1-txt">
            <div className="sec5-1-prt1-1-txt1">
              Discuss your quote with one of our executives.
            </div>
            <div className="sec5-1-prt1-1-txt2">
              Have questions or specific needs? Discuss your customized quote
              directly with one of our experienced executives. We're here to
              provide personalized solutions and address any inquiries you may
              have. Let's start the conversation and tailor our services to
              fit your business.
            </div>
          </div>
        </div>
        <div className="sec5-1-prt1-1">
          <div className="sec5-1-prt1-1-num1">
            <div className="sec5-1-prt1-1-num">3</div>
          </div>
          <div className="sec5-1-prt1-1-txt">
            <div className="sec5-1-prt1-1-txt1">Get started.</div>
            <div className="sec5-1-prt1-1-txt2">
              Excited to elevate your truck dispatching experience? It's time
              to get started! With our comprehensive solutions and dedicated
              support, you can hit the road with confidence. Let's embark on
              this journey together and unlock the full potential of your
              trucking operations.
            </div>
          </div>
        </div>
      </div>
      <ContactForm/>
    </div>
  </div>
  )
}

export default ContactSection