import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <div className="flex privacyBack items-center justify-center w-full !h-[300px] sm:!h-[512px]">
        <span className="text-[28px] sm:text-[64px] font-[700] text-[#FFF]">
          Privacy Policy
        </span>
      </div>

      <div class="Sec9">
        <div class="Sec9-1 ">
          Welcome to Jeffery Logistics ("we," "our," "us"). We are committed to
          protecting your privacy and ensuring you have a positive experience on
          our website and in using our services.
        </div>
        <div class="Sec9-2">
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Information We Collect</div>
            <div class="Sec9-2-prt2-2">
              <div class="privacy-p1 privacy-p2">
                We collect information to provide better services to all our
                users. We collect information in the following ways:
              </div>
              <ul class="privacy-list">
                <li class="Sec9-2-prt2-2 privacy-p2">
                  Information You Give Us: When you sign up for our services, we
                  ask for personal information, such as your name, email
                  address, phone number, company name, and billing information.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  Information We Get from Your Use of Our Services: We collect
                  information about the services you use and how you use them,
                  such as when you visit our website, the pages you visit, and
                  the links you click on.
                </li>
              </ul>
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">How We Use Information</div>
            <div class="Sec9-2-prt2-2">
              <div class="privacy-p1 privacy-p2">
                We use the information we collect for the following purposes:
              </div>
              <ul class="privacy-list">
                <li class="Sec9-2-prt2-2 privacy-p2">
                  To Provide Our Services: We use your information to process
                  transactions, manage your account, and deliver the services
                  you have requested.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  To Improve Our Services: We use the information to understand
                  how our services are being used and to make improvements.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  To Communicate with You: We may use your information to send
                  you updates, newsletters, marketing materials, and other
                  information that may be of interest to you. You can opt out of
                  these communications at any time.
                </li>
              </ul>
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Information Sharing and Disclosure</div>
            <div class="Sec9-2-prt2-2">
              <div class="privacy-p1 privacy-p2">
                We do not share personal information with companies,
                organizations, and individuals outside of Jeffery Logistics
                unless one of the following circumstances applies:
              </div>
              <ul class="privacy-list">
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to access – You have the right to request copies of
                  your personal data.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </li>
              </ul>
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Changes to This Privacy Policy</div>
            <div class="Sec9-2-prt2-2">
              <div class="privacy-p1 privacy-p2">
                We do not share personal information with companies,
                organizations, and individuals outside of Jeffery Logistics
                unless one of the following circumstances applies:
              </div>
              <ul class="privacy-list">
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to access – You have the right to request copies of
                  your personal data.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to rectification – You have the right to request
                  that we correct any information you believe is inaccurate.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to erasure – You have the right to request that we
                  erase your personal data, under certain conditions.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to restrict processing – You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to object to processing – You have the right to
                  object to our processing of your personal data, under certain
                  conditions.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  The right to data portability – You have the right to request
                  that we transfer the data that we have collected to another
                  organization, or directly to you, under certain conditions.
                </li>
              </ul>
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Data Security</div>
            <div class="Sec9-2-prt2-2">
              We work hard to protect [Your Company Name] and our users from
              unauthorized access to or unauthorized alteration, disclosure, or
              destruction of information we hold. We implement various security
              measures to ensure the safety of your personal information.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Data Retention</div>
            <div class="Sec9-2-prt2-2">
              We retain personal information we collect from you where we have
              an ongoing legitimate business need to do so (for example, to
              provide you with a service you have requested or to comply with
              applicable legal, tax, or accounting requirements).
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Your Data Protection Rights</div>
            <div class="Sec9-2-prt2-2">
              <div class="privacy-p1 privacy-p2">
                Depending on your location, you may have the following rights
                regarding your personal data:
              </div>
              <ul class="privacy-list">
                <li class="Sec9-2-prt2-2 privacy-p2">
                  With Your Consent: We will share personal information with
                  companies, organizations, or individuals outside of Jeffery
                  Logistics when we have your consent to do so.
                </li>
                <li class="Sec9-2-prt2-2 privacy-p2">
                  For Legal Reasons: We will share personal information with
                  companies, organizations, or individuals outside of Jeffery
                  Logistics if we have a good-faith belief that access, use,
                  preservation, or disclosure of the information is reasonably
                  necessary to:
                </li>
              </ul>
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">Changes to This Privacy Policy</div>
            <div class="Sec9-2-prt2-2">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </div>
          </div>
          <div class="Sec9-2-prt1">
            <div class="Sec9-2-prt1-1">User Responsibilities</div>
            <div class="Sec9-2-prt2-2">
              <div class="privacy-p1 privacy-p2 privacy-p3">
                If you have any questions about this Privacy Policy, please
                contact us: 
              </div>
              <a href="mailto:hello@jefferylogistics.online" class=" privacy-p2 privacy-p3">
                By email: hello@jefferylogistics.online
              </a>
              <a href="tel:7738401622" class=" privacy-p3">Call: 773 840-1622 </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
