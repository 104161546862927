import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';

function Hero() {
  return (
    <div className="hero-container" style={{position: 'relative'}}>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="images/front-trck-img.png"
            className="  sec1-img w-full"
            alt="..."
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="images/2.jpg"
            className="  sec1-img w-full"
            alt="..."
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="images/3.jpg"
            className="  sec1-img w-full"
            alt="..."
          />
        </SwiperSlide>
      </Swiper>
      <div className="sec1" id="sec1-cover" style={{position: 'absolute', inset: '0', zIndex: '1'}}>
        <div className="sec1-1">Truck Dispatching</div>
        <div className="sec1-2">
          We are a company that provides top-notch services for owner-operators
          and small trucking companies. We help find the best possible solution.
        </div>
        <a className="sec1-3-btn" href="#sec5-form">
          <div className="sec1-3">Join Us</div>
        </a>
      </div>
    </div>
  );
}

export default Hero;
